import { useState, useEffect, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp, faPlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffModal from "../../components/StaffModal";
import { getBoatsFromApi, deleteBoat, addBoat, editBoat } from "../../api/boat";
import { getBoatTypesFromApi } from "../../api/boatTypes";
import { Boat, BoatType, Sport } from "../../types";
import { Form } from "react-bootstrap";
import StatusIcon from "../../components/StatusIcon";
import { Controller, ValidateResult, useForm } from "react-hook-form";
import TableFilter from "../../components/TableFilter";
import { NavLink } from "react-router-dom";
import TableSearch from "../../components/TableSearch";

function BoatManager() {
  let mounted = useRef(true);
  const { t } = useTranslation();
  const [editElement, setEditElement] = useState<Boat | undefined>(undefined);
  const [deleteElement, setDeleteElement] = useState<number>(-1);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [boats, setBoats] = useState<Boat[]>([]);
  const [boatTypes, setBoatTypes] = useState<BoatType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [boatNameSearch, setBoatNameSearch] = useState<string>("");
  const [boatTypeSearch, setBoatTypeSearch] = useState<string>("");
  const [boatStatusSearch, setBoatStatusSearch] = useState<string[]>(["0", "1", "2", "3"]);
  const [sportSearch, setSportSearch] = useState<string>("");

  const [sortBy, setSortBy] = useState<string>("name");
  const [isAsc, setIsAsc] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<Boat>({
    mode: "onChange",
    reValidateMode: 'onChange'
  });

  function setSort(by: string) {
    if (sortBy !== by) {
      setSortBy(by);
      setIsAsc(false);
    } else {
      setIsAsc(!isAsc);
    }
  }

  function getSportString(sports: Sport[] | undefined): string {
    return sports?.map((sport) => sport.name)
      .sort((a, b) => a.localeCompare(b))
      .join(", ") || ""
  }

  const getBoats = async () => {
    const boats = (await getBoatsFromApi() || []);
    if (mounted) {
      setBoats(boats.result);
    }
  };
  async function getBoatTypes() {
    const boatTypes = (await getBoatTypesFromApi() || []);
    if (mounted) {
      setBoatTypes(boatTypes.result);
    }
  };

  useEffect(() => {
    getBoats();
    getBoatTypes();
    return () => {
      mounted.current = false;
    }
  }, []);

  return (
    <div className="m-1 h-100">
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>
              <div className="header">
                <span onClick={() => { setSort("name") }}>
                  {t("boatManager.BoatName")}
                  {sortBy === "name" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={boatNameSearch} onChange={(data) => { setBoatNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("sports") }}>
                  {t("sports.Name")}
                  {sortBy === "sports" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={sportSearch} onChange={(data) => { setSportSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("boattype") }}>
                  {t("boatManager.BoatType")}
                  {sortBy === "boattype" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={boatTypeSearch} onChange={(data) => { setBoatTypeSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">

                <span onClick={() => { setSort("status") }}>
                  {t("boatManager.Status")}
                  {sortBy === "status" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableFilter options={[
                  { label: t("boatManager.available"), value: "0" },
                  { label: t("boatManager.locked"), value: "1" },
                  { label: t("boatManager.rented"), value: "2" },
                  { label: t("boatManager.overdue"), value: "3" }
                ]} state={boatStatusSearch} onChange={(data) => { setBoatStatusSearch(data) }}></TableFilter>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody style={{ overflowY: "scroll", maxHeight: "100px" }}>
          {boats
            .map((b) => {
              b.boattype = b.boattype || "";
              return b;
            })
            .sort((a, b) => {
              if (sortBy === "boattype") {
                let boatTypeA = boatTypes.find(t => t.id === a.boattype);
                let boatTypeB = boatTypes.find(t => t.id === b.boattype);
                return (boatTypeB?.name || "").localeCompare((boatTypeA?.name || "")) * (isAsc ? 1 : -1);
              }
              if (sortBy === "sports") {
                let sportStringA = getSportString(boatTypes.find(t => t.id === a.boattype)?.Sports);
                let sportStringB = getSportString(boatTypes.find(t => t.id === b.boattype)?.Sports);
                return sportStringB.localeCompare(sportStringA) * (isAsc ? 1 : -1);
              }
              return (b[sortBy as keyof Boat]?.toString().localeCompare(a[sortBy as keyof Boat]?.toString() || "") || 0) * (isAsc ? 1 : -1)
            })
            .filter((boat) => boat.name.toLocaleLowerCase().includes(boatNameSearch.toLocaleLowerCase()))
            .filter((boat) => {
              const boatType = boatTypes.find(t => t.id === boat.boattype);
              if (boatType === undefined || boatType.Sports.length === 0) {
                // If no BoatType, and therefore no Sport exists, always show the boat
                return true;
              }
              const sportNames = boatType.Sports.map(sport => sport.name.toLowerCase());
              return sportNames.some(name => name.includes(sportSearch.toLowerCase()));
            })
            .filter((boat) => {
              if (boat.boattype === "") {
                // If no BoatType exists, always show the boat
                return true;
              }
              return boatTypes.find(t => t.id === boat.boattype)?.name.toLocaleLowerCase().includes(boatTypeSearch.toLocaleLowerCase())
            })
            .filter((boat) => boatStatusSearch.includes(boat.status.toString()))
            .map((x, i) => (
              <tr key={x.id}
                style={{ cursor: "pointer", backgroundColor: x.boattype === "" || boatTypes.find(t => t.id === x.boattype)?.Sports.length === 0 ? "#ffd0d0" : "#ffffff" }}
                onClick={() => {
                  reset();
                  setEditElement(x);
                  setValue('id', x.id);
                  setValue('name', x.name);
                  setValue('status', x.status);
                  setValue('boattype', x.boattype);
                }}
              >
                <td>
                  {x.name}
                </td>
                <td>
                  {getSportString(boatTypes.find(t => t.id === x.boattype)?.Sports)}
                </td>
                <td>{boatTypes.find(t => t.id === x.boattype)?.name}</td>
                <td><StatusIcon status={x.status}></StatusIcon></td>
                <td>
                  <div className="d-inline-flex">
                    <NavLink to={"/staff/statistics/"}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="text-secondary clickableIcon"
                        size="1x"
                      />
                    </NavLink>
                    <div
                      className="mx-2"
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteElement(boats.indexOf(x));
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-danger clickableIcon"
                        size="1x"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        className="d-flex px-2 py-1 justify-content-end bg-white border-top"
        style={{ position: "sticky", right: "5px", bottom: "0", zIndex: 3 }}
      >
        <Button
          onClick={() => {
            reset();
            setAdding(true);
            setEditElement({ id: "", name: "", "status": 0 } as Boat);
            setValue('id', undefined as any);
            setValue('name', "");
            setValue('status', 0);
          }}
          variant="secondary"
        >
          <FontAwesomeIcon icon={faPlus} className="text-white me-2" />
          {t("boatManager.AddBoat")}
        </Button>
      </div>


      <StaffModal
        header={isAdding ? t("boatManager.AddBoat") : t("boatManager.EditBoat")}
        show={!!editElement}
        successText={isAdding ? t("common.Add") : t("common.Edit")}
        loadingNext={loading}
        disableNext={!isValid}
        onHide={() => {
          setEditElement(undefined);
          setAdding(false);
        }}
        onSuccess={() => {
          setLoading(true);
          handleSubmit((data) => {
            if (isAdding) {
              addBoat(data).then(async () => {
                await getBoats();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            } else {
              editBoat(data).then(async () => {
                await getBoats();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            }
          })()
        }}
      >
        <Form>
          <Controller
            name="name"
            control={control}
            defaultValue={editElement?.name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("boatManager.BoatName")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  isInvalid={!!errors.name}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("boatManager.BoatName"),
                }),
              },
              validate: (value: string): ValidateResult => {
                const isRedundant = boats.some((boat) => boat.name === value && boat.id !== editElement?.id);
                if (isRedundant) {
                  return t("common.messages.nameExists") as ValidateResult;
                }
                return undefined;
              },
            }}
          />
          <Controller
            name="boattype"
            control={control}
            defaultValue={editElement?.boattype}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("boatManager.BoatType")}</Form.Label>
                <Form.Select aria-label="Default select example" {...field} isInvalid={!!errors.boattype}>
                  <option></option>
                  {boatTypes
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => (
                      <option key={x.id} value={x.id}> {x.name} </option>
                    ))
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.boattype?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("boatManager.BoatType"),
                }),
              },
            }}
          />
          <Controller
            name="status"
            control={control}
            defaultValue={editElement?.status}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("boatManager.Status")}</Form.Label>
                <Form.Select aria-label="Default select example" {...field}>
                  <option value={0}>{t("boatManager.available")}</option>
                  <option value={1}>{t("boatManager.locked")}</option>
                </Form.Select>
              </div>
            )}
          />
        </Form>

      </StaffModal>


      <StaffModal
        header={t("boatManager.DeleteBoat")}
        hideColor="secondary"
        successText={t("common.Delete")}
        successColor="danger"
        show={deleteElement !== -1}
        loadingNext={loading}
        onHide={() => {
          setDeleteElement(-1);
        }}
        onSuccess={async () => {
          setLoading(true);
          await deleteBoat({ id: boats[deleteElement].id })
          await getBoats();
          setDeleteElement(-1);
          setLoading(false);
        }}
      >
        <span>
          {t("boatManager.messages.DeleteText", { val: boats[deleteElement]?.name })}
        </span>
      </StaffModal>
    </div>
  );
}

export default BoatManager;
