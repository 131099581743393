import { useEffect, useState, useRef } from "react";
import { Form, Table, Tab, Tabs, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckIn } from "../../types";
import { getAllCheckIns, editMemo } from "../../api/checkIn";
import { faArrowDown, faArrowUp, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import TableSearch from "../../components/TableSearch";
import TableDateFilter from "../../components/TableDateFilter";
import { Controller, ValidateResult, useFieldArray, useForm } from "react-hook-form";
import StaffModal from "../../components/StaffModal";
import Divider from "../../components/layout/Divider";


function AllRents() {
  let mounted = useRef(true);
  const { t, i18n } = useTranslation();
  const [checkIns, setcheckIns] = useState<CheckIn[]>([]);
  const [commentSearch, setCommentSearch] = useState<string>("");
  const [sportSearch, setSportSearch] = useState<string>("");
  const [boatNameSearch, setBoatNameSearch] = useState<string>("");
  const [accessoryNameSearch, setAccessoryNameSearch] = useState<string>("");
  const [adminMemoSearch, setAdminMemoSearch] = useState<string>("");

  const [editElement, setEditElement] = useState<CheckIn | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [semester, setSemester] = useState<any>(new Date().getFullYear());

  const [authorSearch, setAuthorSearch] = useState<string>("");

  const [dateStartSearch, setDateStartSearch] = useState<string>("");
  const [dateEndSearch, setDateEndSearch] = useState<string>("");

  const [sortBy, setSortBy] = useState<string>("date");
  const [isAsc, setIsAsc] = useState<boolean>(true);

  async function getcheckIns(year = -1) {
    if (year === -1) {
      year = semester;
    }
    const checkIns = await getAllCheckIns(year);
    if (mounted) {
      setcheckIns(checkIns);
    }
  }

  function setSort(by: string) {
    if (sortBy !== by) {
      setSortBy(by);
      setIsAsc(false);
    } else {
      setIsAsc(!isAsc);
    }
  }

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<CheckIn>({
    mode: "onChange",
    reValidateMode: 'onChange',
  });

  const {
    fields: additionalClients,
    append: appendPerson,
    remove: removePerson,
    replace: replacePersons,
  } = useFieldArray({
    control,
    name: "additionalClients",
  });

  useEffect(() => {
    getcheckIns();
    return () => {
      mounted.current = false;
    }
  }, []); // Empty List means useEffect is only called when first rendering the site, any other actions do not lead to a reload.

  let sortedCheckIns = checkIns
    .map<CheckIn>((x: CheckIn) => {
      // Check to see whether a boat was already deleted. If so, replace the Boat with a dummy object, to still allow for the CheckIn to be rendered.
      if (x.Boat === null) {
        x.Boat = { id: "", name: "", status: 1, boattype: "None" };
      }
      if (x.Sport === null) {
        x.Sport = { id: "", name: "", color: "" };
      }
      return x
    })
    .filter((checkIn) => {
      if (dateEndSearch !== "" && dateStartSearch !== "") {
        return Date.parse(checkIn.date) >= Date.parse(dateStartSearch) && Date.parse(checkIn.date) <= Date.parse(dateEndSearch);
      }
      else if (dateStartSearch !== "" && dateEndSearch === "") {
        return Date.parse(checkIn.date) === Date.parse(dateStartSearch);
      }
      //dateStartSearch should never be Empty when dateEndSearch is not empty
      else {
        return true;
      }
    })
    .filter((checkIn) => checkIn.Boat.name.toLocaleLowerCase().includes(boatNameSearch.toLocaleLowerCase()))
    .filter((checkIn) => checkIn.Sport.name.toLocaleLowerCase().includes(sportSearch.toLocaleLowerCase()))
    .filter((checkIn) => (checkIn.Accessory?.name || "").toLocaleLowerCase().includes(accessoryNameSearch.toLocaleLowerCase()))
    .filter((checkIn) => checkIn.fullNameOfResponsibleClient.toLocaleLowerCase().includes(authorSearch.toLocaleLowerCase()))
    .filter((checkIn) => {
      const note = checkIn.note || ""; // Falls checkIn.note null oder undefined ist, empty string gesetzt. Ohne diese Fallunterscheidung verschwinden die Einträge ohne Kommentar.
      return note.toLowerCase().includes(commentSearch.toLocaleLowerCase());
    })
    .filter((checkIn) => {
      const adminMemo = checkIn.adminMemo || "";
      return adminMemo.toLowerCase().includes(adminMemoSearch.toLocaleLowerCase());
    })
    .sort((a, b) => {
      if (sortBy === "date") {
        return (Date.parse(b.startTime) - Date.parse(a.startTime)) * (-1);
      }
      else if (sortBy === "boat") {
        return b.Boat.name.localeCompare(a.Boat.name) * (isAsc ? 1 : -1);
      }
      else if (sortBy === "sport") {
        return b.Sport.name.localeCompare(a.Sport.name) * (isAsc ? 1 : -1);
      }
      else if (sortBy === "accessory") {
        return (b.Accessory?.name || "").localeCompare(a.Accessory?.name || "") * (isAsc ? 1 : -1);
      }
      else if (sortBy === "returned") {
        return (Number(a.returned === b.returned) * 2 - 1) * (isAsc ? 1 : -1);
      }
      else if (sortBy === "isCourse") {
        return (Number(a.isCourse === b.isCourse) * 2 - 1) * (isAsc ? 1 : -1);
      }
      else {
        return ((b[sortBy as keyof CheckIn]?.toString() || "").localeCompare(a[sortBy as keyof CheckIn]?.toString() || "") || 0) * (isAsc ? 1 : -1)
      }
    });

  if (sortBy === "date" && isAsc) {
    sortedCheckIns = sortedCheckIns.reverse();
  }

  function renderTabMenu() {

    let curYear = new Date().getFullYear();
    let minYear = 2020;

    let years = [];
    for (let year = curYear; year >= minYear; year--) {
      years.push(year);
    }

    return (
      <div>
        <Tabs
          id="controlled-tab"
          activeKey={semester}
          onSelect={(k) => { setSemester(k); getcheckIns(Number(k)); }}
        >
          {years.map(v => {
            return (
              <Tab key={v} eventKey={v} title={v}>
              </Tab>
            )
          })
          }
        </Tabs>
      </div >
    )
  }

  return (
    <div className="m-1 h-100">
      {renderTabMenu()}
      <Table responsive striped bordered hover>
        <thead>
          <tr style={{ maxWidth: "100%" }}>
            <th>
              <div className="header">
                <span onClick={() => { setSort("boat") }}>
                  {t("allRents.Boatname")}
                  {sortBy === "boat" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={boatNameSearch} onChange={(data) => { setBoatNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("accessory") }}>
                  {t("allRents.Accessoryname")}
                  {sortBy === "accessory" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={accessoryNameSearch} onChange={(data) => { setAccessoryNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("returned") }}>
                  {t("allRents.returned")}
                  {sortBy === "returned" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("isCourse") }}>
                  {t("allRents.isCourse")}
                  {sortBy === "isCourse" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("sport") }}>
                  {t("allRents.sport")}
                  {sortBy === "sport" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={sportSearch} onChange={(data) => { setSportSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("fullNameOfResponsibleClient") }}>
                  {t("allRents.fullNameOfResponsibleClient")}
                  {sortBy === "fullNameOfResponsibleClient" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={authorSearch} onChange={(data) => { setAuthorSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("note") }}>
                  {t("allRents.Comment")}
                  {sortBy === "note" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={commentSearch} onChange={(data) => { setCommentSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("adminMemo") }}>
                  {t("allRents.adminMemo")}
                  {sortBy === "adminMemo" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={adminMemoSearch} onChange={(data) => { setAdminMemoSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("date") }}>
                  {t("allRents.Date")}
                  {sortBy === "date" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableDateFilter stateFrom={dateStartSearch} stateTo={dateEndSearch} onChangeTo={(data) => {
                  setDateEndSearch(data);
                }} onChangeFrom={(data) => {
                  setDateStartSearch(data);
                }}></TableDateFilter>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            sortedCheckIns
              .map((x, i) => (
                <tr key={x.id} style={{ cursor: "pointer", maxWidth: "100%" }}
                  onClick={() => {
                    reset();
                    setEditElement(x);
                    replacePersons(x.additionalClients.map((entry: any) => { return { passenger: entry } }));
                  }}>
                  {/* Instead of using useTranslation for the Deleted Boat Fill in, we hardcode it here.
                      Looks ugly, but actually needed. If se set the name in the map function checking for null-boats, the name is not updated when changing the language.
                      This would lead to the incorrect Name being displayed once the language is switched.
                      So, we just do that manually here to always have the correct language without needing to update.
                  */}
                  <td>{x.Boat.name === "" ? i18n.language === "de" ? (<em>Gelöschtes Boot</em>) : (<em>Deleted Boat</em>) : x.Boat.name}</td>
                  <td>{x.Accessory?.name}</td>
                  <td>{x.returned ? t("allRents.Yes") : t("allRents.No")}</td>
                  <td>{x.isCourse ? t("allRents.Yes") : t("allRents.No")}</td>
                  <td>{x.Sport.name === "" ? i18n.language === "de" ? (<em>Gelöschte Sportart</em>) : (<em>Deleted Sport</em>) : x.Sport.name}</td>
                  <td>{x.fullNameOfResponsibleClient}</td>
                  <td className="table-text-field">{x.note}</td>
                  <td className="table-text-field">{x.adminMemo}</td>
                  <td>
                    {new Date(x.date).toLocaleDateString()}
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>

      <StaffModal
        header={t("boatManager.EditBoat")}
        show={!!editElement}
        successText={t("common.Edit")}
        loadingNext={loading}
        disableNext={!isValid}
        onHide={() => {
          setEditElement(undefined);
        }}
        onSuccess={() => {
          setLoading(true);
          handleSubmit((data) => {
            editMemo(editElement?.id || "", data).then(async () => {
              await getcheckIns();
              setEditElement(undefined);
              setLoading(false);
            });
          })()
        }}
      >
        <Form>
          <Controller
            name="Boat.name"
            control={control}
            defaultValue={editElement?.Boat.name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("boatManager.BoatName")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />

          <Controller
            name="Accessory.name"
            control={control}
            defaultValue={editElement?.Accessory?.name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accessoryOverview.AccessoryName")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />

          <Divider />
          <Controller
            name="destination"
            control={control}
            defaultValue={editElement?.destination}
            render={({ field }) => (
              <div className="mb-2 required">
                <Form.Label>{t("bookingForm.labelDestination")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  isInvalid={!!errors.destination}
                  disabled={true}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.destination?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("bookingForm.labelDestination"),
                }),
              },
              maxLength: {
                value: 500,
                message: t("common.messages.length", { val: 500 })
              },
            }}
          />


          <Divider />



          <Controller
            name="fullNameOfResponsibleClient"
            control={control}
            defaultValue={editElement?.fullNameOfResponsibleClient}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("allRents.fullNameOfResponsibleClient")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />

          <div className="d-flex justify-content-between">
            <h5>{t("bookingForm.labelAdditionalNames")}</h5>
            <Button
              variant="secondary"
              // disabled={watch("additionalClients").length === (editElement?.Boat?.BoatType?.seats || 1) - 1}
              disabled={true}
              type="button"
              onClick={() => appendPerson({ passenger: "" })}
            >
              +
            </Button>
          </div>

          <ul>
            {additionalClients.map((item: any, index: number) => (
              <div key={item.id}>
                <div key={"child" + item.id} className="my-2">
                  <Controller
                    defaultValue={item}
                    name={`additionalClients.${index}.passenger` as const}
                    control={control}
                    render={({ field, fieldState }) => (
                      <div className="required">
                        <div className="mb-2 d-flex">
                          <Form.Control
                            type="text"
                            {...field}
                            isInvalid={!!fieldState.invalid}
                            disabled={true}
                          />
                          <Button
                            className="mx-3"
                            variant="danger"
                            type="button"
                            onClick={() => removePerson(index)}
                            disabled={true}
                          >
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              className="text-white"
                            />
                          </Button>
                        </div>
                        {/* Doesn't work! Only appears when in the div above. TODO? */}
                        <Form.Control.Feedback type="invalid">
                          {errors.additionalClients && errors.additionalClients[index] && errors.additionalClients[index]?.passenger?.message}
                        </Form.Control.Feedback>
                      </div>
                    )}
                    rules={{
                      maxLength: {
                        value: 500,
                        message: t("common.messages.length", { val: 500 })
                      },
                    }}
                  />
                </div>
              </div>
            ))}
          </ul>
          <Divider />

          <Controller
            name="returned"
            control={control}
            defaultValue={editElement?.returned}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Check
                  type="checkbox"
                  id="returned"
                  name={field.name}
                  disabled={true}
                  defaultChecked={editElement?.returned}
                  inline
                />
                <Form.Label>{t("allRents.returned")}</Form.Label>
              </div>
            )}
          />

          <Controller
            name="isCourse"
            control={control}
            defaultValue={editElement?.isCourse}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Check
                  type="checkbox"
                  id="isCourse"
                  name={field.name}
                  disabled={true}
                  defaultChecked={editElement?.isCourse}
                  inline
                />
                <Form.Label>{t("allRents.isCourse")}</Form.Label>
              </div>
            )}
          />

          <Controller
            name="date"
            control={control}
            defaultValue={editElement?.date}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("allRents.Date")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />
          <Controller
            name="note"
            control={control}
            defaultValue={editElement?.note || ""}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("comments.Comment")}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  {...field}
                  disabled={true}
                  rows={5}
                />
              </div>
            )}
          />

          <Controller
            name="adminMemo"
            control={control}
            defaultValue={editElement?.adminMemo || ""}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("comments.adminMemo")}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  {...field}
                  rows={5}
                />
              </div>
            )}
          />

        </Form>

      </StaffModal>

    </div >
  );
}

export default AllRents;
