import { useState, useEffect, useRef } from "react";
import { Button, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BoatTile from "../../components/BoatTile";
import StaffModal from "../../components/StaffModal";
import BoatInfo from "../../components/forms/BoatInfo";
import { useNavigate } from 'react-router-dom';
import { getBoatsOverview, lock, unlock, } from "../../api/boat";
import { Accessory, OverviewBoat, Sport } from "../../types";
import { checkOut, getCheckInByBoatId } from "../../api/checkIn";
import { getSport, getSportsFromApi } from "../../api/sport";
import { getAccessoriesFromApi, lockAccessory, unlockAccessory } from "../../api/accessory";
import { stringToColour } from "../../styles/colours";


function BoatOverview({ isTrainer }: any) {
	let mounted = useRef(true);
	const { t } = useTranslation();
	const [infoElement, setInfoElement] = useState<any>(undefined);
	const [boats, setBoats] = useState<OverviewBoat[]>([]);
	const [sports, setSports] = useState<Sport[]>([]);
	const [accessories, setAccessories] = useState<Accessory[]>([]);
	const navigate = useNavigate();

	const [availableBoatSportSelect, setAvailableBoatSportSelect] = useState<number>(-1);
	const [rentedBoatSportSelect, setRentedBoatSportSelect] = useState<number>(-1);

	async function getSports() {
		const sports = await getSportsFromApi();
		if (mounted) {
			setSports(sports);
		}
	}

	function SportDropdown(SportIdx: number, setSportIdx: any, id: string) {
		return (
			<DropdownButton size="lg" id={id} title={SportIdx === -1 ? t("boatOverview.chooseSport") : sports[SportIdx].name} style={{ margin: "10px" }}>
				<Dropdown.Item onClick={(e) => setSportIdx(-1)}>{t("boatOverview.allSports")}</Dropdown.Item>
				{sports.map((value, index) => {
					return <Dropdown.Item onClick={(e) => setSportIdx(index)}>{value.name}</Dropdown.Item>
				})}
			</DropdownButton>
		);
	}

	const filterForSports = (x: OverviewBoat, filterIndex: number) => {
		if (filterIndex === -1) {
			return true
		}
		return x.boattype?.Sports?.find((entry) => entry.id === sports[filterIndex].id) !== undefined
	}

	const getBoats = async () => {
		const boats = (await getBoatsOverview()) || [];
		if (mounted) {
			setBoats(boats.filter(x => x.boattype !== undefined).sort((a, b) => a.name.localeCompare(b.name)));
		}
	};

	const getAccessories = async () => {
		const accessories = (await getAccessoriesFromApi() || []);
		if (mounted) {
			setAccessories(accessories.result);
		}
	};

	useEffect(() => {
		getSports();
		getBoats();
		getAccessories();
		let interval = setInterval(() => { getBoats(); getAccessories(); }, 20000)
		return () => {
			clearInterval(interval);
			mounted.current = false;
		}
	}, []);

	let successProps: any = { hideSuccess: true };
	if (!isTrainer) {
		successProps = {
			successColor: "dark",
			successText: t("boatOverview.EndRide"),
			onSuccess: () => {
				checkOut({ id: infoElement.id, note: "", bookingType: "force" }).then(async () => {
					await getBoats();
				});
				setInfoElement(undefined)
			}
		}
	}

	return (
		<div className="text-center bg-light">
			<div className="boatViews text-center">
				<Row xs={1} xl={2}>

					{/* RENTED AND OVERDUE BOATS */}

					<div>
						<div className="rentedOverdueView bg-white border my-2">
							<h2 className="my-3">{t("boatOverview.labelOverdue")}</h2>
							<div className="py-1">
								<Container >
									{(boats.filter(x => x.status === 3).length > 0) && <div className="mb-3">
										<div className="overdue-tiles text-center">
											<Row xs={1} md={2} >
												{boats.filter(x => x.status === 3)
													.sort((a, b) => {
														if (a.currentCheckIn && b.currentCheckIn) {
															return Date.parse(a.currentCheckIn.estimatedEndTime) - Date.parse(b.currentCheckIn.estimatedEndTime);
														}
														return 0;
													})
													.slice(0, (isTrainer ? undefined : 6))
													.map(x =>
														<BoatTile
															isInUse={true}
															typecolor={x.boattype?.color || stringToColour(x.boattype?.name || "undefined")}
															key={x.id}
															boatName={x.name}
															responsibleClient={x.currentCheckIn?.fullNameOfResponsibleClient || undefined}
															accessory={x.currentCheckIn?.Accessory ? t("boatOverview.hasAccessory") + x.currentCheckIn?.Accessory?.name : ""}
															time={x.currentCheckIn?.estimatedEndTime}
															isCourse={x.currentCheckIn?.isCourse ? t("boatOverview.isCourse") : ""}
															additionalClients={
																((x.currentCheckIn?.additionalClients && x.currentCheckIn.additionalClients.length) || 0)
																+ 1 + " " + t("boatOverview.of") + " " + x.boattype?.seats + " " + t("boatOverview.persons")
															}
															overdue={true}
															isTrainer={isTrainer}
															clickEvent={async () => {
																let checkIn = await getCheckInByBoatId(x.id);
																checkIn.result.boatName = x.name;
																let sport = await getSport(checkIn.result.SportId);
																checkIn.result.sport = sport.result.name;
																checkIn.result.Accessory = x.currentCheckIn?.Accessory;
																setInfoElement(checkIn.result);
															}} />
													)}
											</Row>
										</div>
									</div>}
									{(boats.filter(x => x.status === 3).length === 0) && <div style={{ height: "45px", verticalAlign: "middle" }}>{t("boatOverview.noBoatsAvailable")}</div>}
									{(boats.filter(x => x.status === 3).length >= 6) && <Button variant="light" onClick={() => navigate('/staff/boats')} className="mb-3 shadow-sm bg-white border w-50 text-uppercase rounded-pill">{t("boatOverview.buttonShowAll")}</Button>}
								</Container>
							</div>
						</div>
					</div>

					<div>
						<div className="rentedView bg-white border my-2">
							<h2 className="my-3">{t("boatOverview.labelCurrentlyRented")}</h2>
							{SportDropdown(rentedBoatSportSelect, setRentedBoatSportSelect, "available")}
							<div className="py-1">
								<Container>
									{(boats.filter(x => x.status === 2).length > 0) && <div className="mb-3">
										<div className="rented-tiles text-center">
											<Row xs={1} md={2}>
												{boats.filter(x => x.status === 2).filter(x => filterForSports(x, rentedBoatSportSelect)).slice(0, (isTrainer ? undefined : 6)).map(x =>
													<BoatTile
														isInUse={true}
														typecolor={x.boattype?.color || stringToColour(x.boattype?.name || "undefined")}
														key={x.id}
														boatName={x.name}
														responsibleClient={x.currentCheckIn?.fullNameOfResponsibleClient || undefined}
														accessory={x.currentCheckIn?.Accessory ? t("boatOverview.hasAccessory") + x.currentCheckIn?.Accessory?.name : ""}
														time={x.currentCheckIn?.estimatedEndTime}
														isCourse={x.currentCheckIn?.isCourse ? t("boatOverview.isCourse") : ""}
														// There is a case that additionalClients is undefined											
														additionalClients={
															((x.currentCheckIn?.additionalClients && x.currentCheckIn.additionalClients.length) || 0)
															+ 1 + " " + t("boatOverview.of") + " " + x.boattype?.seats + " " + t("boatOverview.persons")
														}
														overdue={false}
														isTrainer={isTrainer}
														clickEvent={async () => {
															let checkIn = await getCheckInByBoatId(x.id);
															checkIn.result.boatName = x.name;
															let sport = await getSport(checkIn.result.SportId);
															checkIn.result.sport = sport.result.name;
															checkIn.result.Accessory = x.currentCheckIn?.Accessory;
															setInfoElement(checkIn.result);
														}}
													/>
												)}
											</Row>
										</div>
									</div>}
									{(boats.filter(x => x.status === 2).length === 0) && <div style={{ height: "45px", verticalAlign: "middle" }}>{t("boatOverview.noBoatsAvailable")}</div>}
									{!isTrainer ? (
										(boats.filter(x => x.status === 2).length >= 6) && <Button variant="light" onClick={() => navigate('/staff/boats')} className="mb-3 shadow-sm bg-white border w-50 text-uppercase rounded-pill">{t("boatOverview.buttonShowAll")}</Button>
									) : (<></>)}
								</Container>
							</div>
						</div>
					</div>

					{/* BLOCKED AND AVAILABLE BOATS */}
					{!isTrainer ? (
						<div>
							<div className="blockedView bg-white border my-2">
								<h2 className="my-3">{t("boatOverview.blockedBoats")}</h2>
								<div className="py-1">
									<Container>
										{(boats.filter(x => x.status === 1).length > 0) && <div className="mb-3">
											<div className="blocked-tiles text-center">
												<Row xs={1} md={2}>
													{boats.filter(x => x.status === 1).slice(0, 6).map(x =>
														<BoatTile
															isInUse={false}
															typecolor={x.boattype?.color || stringToColour(x.boattype?.name || "undefined")}
															key={x.id}
															boatName={x.name}
															additionalClients={(x.boattype?.seats || t("boatOverview.infinitePersons")) + " " + t("boatOverview.persons")}
															changeLock={() => {
																unlock({ id: x.id }).then(async () => {
																	await getBoats();
																});
															}}
															blocked={true}
														/>
													)}
												</Row>
											</div>
										</div>}
										{(boats.filter(x => x.status === 1).length === 0) && <div style={{ height: "45px", verticalAlign: "middle" }}>{t("boatOverview.noBoatsAvailable")}</div>}
										{(boats.filter(x => x.status === 1).length >= 6) && <Button variant="light" onClick={() => navigate('/staff/boats')} className="mb-3 shadow-sm bg-white border w-50 text-uppercase rounded-pill">{t("boatOverview.buttonShowAll")}</Button>}
									</Container>
								</div>
							</div>
						</div>
					) : (<></>)}

					<div>
						<div className="availableView bg-white border my-2">
							<h2 className="my-3">{t("boatOverview.availableBoats")}</h2>
							{SportDropdown(availableBoatSportSelect, setAvailableBoatSportSelect, "available")}
							<div className="py-1">
								<Container>
									{(boats.filter(x => x.status === 0).length > 0) && <div className="mb-3">
										<div className="available-tiles text-center">
											<Row xs={1} md={2}>
												{boats.filter(x => x.status === 0).filter(x => filterForSports(x, availableBoatSportSelect)).slice(0, (isTrainer ? undefined : 6)).map(x =>
													<BoatTile
														isInUse={false}
														typecolor={x.boattype?.color || stringToColour(x.boattype?.name || "undefined")}
														key={x.id}
														boatName={x.name}
														additionalClients={(x.boattype?.seats || t("boatOverview.infinitePersons")) + " " + t("boatOverview.persons")}
														blocked={false}
														isTrainer={isTrainer}
														changeLock={() => {
															lock({ id: x.id }).then(async () => {
																await getBoats();
															});
														}} />
												)}
											</Row>
										</div>
									</div>}
									{(boats.filter(x => x.status === 0).length === 0) && <div style={{ height: "45px", verticalAlign: "middle" }}>{t("boatOverview.noBoatsAvailable")}</div>}
									{!isTrainer ? (
										(boats.filter(x => x.status === 0).length >= 6) && <Button variant="light" onClick={() => navigate('/staff/boats')} className="mb-3 shadow-sm bg-white border w-50 text-uppercase rounded-pill">{t("boatOverview.buttonShowAll")}</Button>
									) : (<></>)}
								</Container>
							</div>
						</div>
					</div>

					{/* ACCESSORIES */}
					{!isTrainer ? (
						<>
							<div>
								<div className="blockedView bg-white border my-2">
									<h2 className="my-3">{t("boatOverview.blockedAccessories")}</h2>
									<div className="py-1">
										<Container>
											{(accessories.filter(x => x.status === 1).length > 0) ?
												(<>
													<div className="mb-3">
														<div className="blocked-tiles text-center">
															<Row xs={1} md={2}>
																{accessories.filter(x => x.status === 1).slice(0, 6).map(x =>
																	<BoatTile
																		isInUse={false}
																		key={x.id}
																		boatName={x.name}
																		changeLock={() => {
																			unlockAccessory({ id: x.id }).then(async () => {
																				await getAccessories();
																			});
																		}}
																		blocked={true}
																	/>
																)}
															</Row>
														</div>
													</div>
													{(accessories.filter(x => x.status === 1).length >= 6) &&
														(
															<Button
																variant="light"
																onClick={() => navigate('/staff/accessories')}
																className="mb-3 shadow-sm bg-white border w-50 text-uppercase rounded-pill"
															>
																{t("boatOverview.buttonShowAll")}
															</Button>
														)
													}
												</>
												) : (
													<div style={{ height: "45px", verticalAlign: "middle" }}>{t("boatOverview.noBlockedAccessoriesAvailable")}</div>
												)
											}
										</Container>
									</div>
								</div>
							</div>

							<div>
								<div className="availableView bg-white border my-2">
									<h2 className="my-3">{t("boatOverview.availableAccessories")}</h2>
									<div className="py-1">
										<Container>
											{(accessories.filter(x => x.status === 0).length > 0) ?
												(<>
													<div className="mb-3">
														<div className="available-tiles text-center">
															<Row xs={1} md={2}>
																{accessories.filter(x => x.status === 0).slice(0, 6).map(x =>
																	<BoatTile
																		isInUse={false}
																		key={x.id}
																		boatName={x.name}
																		blocked={false}
																		changeLock={() => {
																			lockAccessory({ id: x.id }).then(async () => {
																				await getAccessories();
																			});
																		}} />
																)}
															</Row>
														</div>
													</div>
													{(accessories.filter(x => x.status === 0).length >= 6) &&
														<Button
															variant="light"
															onClick={() => navigate('/staff/accessories')}
															className="mb-3 shadow-sm bg-white border w-50 text-uppercase rounded-pill"
														>
															{t("boatOverview.buttonShowAll")}
														</Button>
													}
												</>
												) : (
													<div style={{ height: "45px", verticalAlign: "middle" }}>{t("boatOverview.noAccessoriesAvailable")}</div>
												)
											}
										</Container>
									</div>
								</div>
							</div>
						</>
					) : (<></>)}

				</Row>
			</div>

			<StaffModal
				header={t("boatOverview.BoatInfo")}
				show={infoElement}
				onHide={() => { setInfoElement(undefined) }}
				{...successProps}
			>
				<BoatInfo {...infoElement} />
			</StaffModal>

		</div>
	);
}

export default BoatOverview;
