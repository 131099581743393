import { useEffect, useState, useRef } from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp, faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffModal from "../../components/StaffModal";
import { Form } from "react-bootstrap";
import { Controller, useForm, useFieldArray, ValidateResult } from "react-hook-form";
import { AccessoryType, BoatType } from "../../types";
import {
    deleteAccessoryType,
    getAccessoryTypesFromApi,
    addAccessoryType,
    editAccessoryType,
} from "../../api/accessoryTypes";
import { getBoatTypesFromApi } from "../../api/boatTypes";
import { NavLink } from "react-router-dom";
import TableSearch from "../../components/TableSearch";


function AccessoryTypeOverview() {
    let mounted = useRef(true);
    const { t } = useTranslation();
    const [editElement, setEditElement] = useState<AccessoryType | undefined>(
        undefined
    );
    const [deleteElement, setDeleteElement] = useState<number>(-1);
    const [isAdding, setAdding] = useState<boolean>(false);
    const [accessoryTypes, setAccessoryTypes] = useState<AccessoryType[]>([]);
    const [boattypes, setBoatTypes] = useState<BoatType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [typeNameSearch, setTypeNameSearch] = useState<string>("");
    const [typeBoatTypeSearch, setTypeBoatTypeSearch] = useState<string>("");

    const [sortBy, setSortBy] = useState<string>("name");
    const [isAsc, setIsAsc] = useState<boolean>(false);

    const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

    function setSort(by: string) {
        if (sortBy !== by) {
            setSortBy(by);
            setIsAsc(false);
        } else {
            setIsAsc(!isAsc);
        }
    }

    function getBoatTypeString(boattypes: BoatType[] | undefined): string {
        return boattypes?.map((boattype) => boattype.name)
            .sort((a, b) => a.localeCompare(b))
            .join(", ") || ""
    }

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm<AccessoryType>({
        mode: "onChange",
        reValidateMode: 'onChange'
    });

    const {
        fields: boattypeField,
        append: appendBoatType,
        remove: removeBoatType,
        replace: replaceBoatType
    } = useFieldArray({
        control,
        name: "BoatTypes",
    });

    async function getAccessoryTypes() {
        let accessoryTypes = await getAccessoryTypesFromApi();
        if (mounted) {
            setAccessoryTypes(accessoryTypes.result);
        }
    }
    async function getBoatTypes() {
        let boattypes = await getBoatTypesFromApi();
        if (mounted) {
            setBoatTypes(boattypes.result);
        }
    }

    useEffect(() => {
        getAccessoryTypes();
        getBoatTypes();
        return () => {
            mounted.current = false;
        }
    }, []);

    return (
        <div className="m-1 h-100">
            <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            <div className="header">
                                <span onClick={() => { setSort("name") }}>
                                    {t("accessoryTypeOverview.Name")}
                                    {sortBy === "name" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                                </span>
                                <TableSearch state={typeNameSearch} onChange={(data) => { setTypeNameSearch(data) }} />
                            </div>
                        </th>
                        <th>
                            <div className="header">
                                <span onClick={() => { setSort("boattype") }}>
                                    {t("accessoryTypeOverview.BoatTypeName")}
                                    {sortBy === "boattype" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                                </span>
                                <TableSearch state={typeBoatTypeSearch} onChange={(data) => { setTypeBoatTypeSearch(data) }} />
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ overflowY: "scroll", maxHeight: "100px" }}>
                    {!accessoryTypes ? <div></div> :
                        accessoryTypes

                            .filter((accessoryType) => accessoryType.name.toLocaleLowerCase().includes(typeNameSearch.toLocaleLowerCase()))
                            .filter((accessoryType) => accessoryType.BoatTypes.map(x => x.name).join(', ').toLocaleLowerCase().includes(typeBoatTypeSearch.toLocaleLowerCase()))
                            .map((x) => (
                                <tr key={x.id}
                                    style={{ cursor: "pointer", backgroundColor: x.BoatTypes.length === 0 ? "#ffd0d0" : "#ffffff" }}
                                    onClick={() => {
                                        reset();
                                        setLoading(false);
                                        setEditElement(x);
                                        setValue("id", x.id);
                                        setValue("name", x.name);
                                        replaceBoatType(x.BoatTypes.length > 0 ? x.BoatTypes : { id: "", name: "", color: "", maxTime: 0, seats: 0, Sports: [] });
                                    }}>
                                    <td>
                                        {x.name}
                                    </td>
                                    <td>{getBoatTypeString(x.BoatTypes)}</td>
                                    <td>
                                        <div className="d-flex">
                                            <NavLink to={"/staff/statistics/accessorytype/" + x.id}>
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-secondary clickableIcon"
                                                    size="1x"
                                                />
                                            </NavLink>
                                            <div
                                                className="mx-2"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setDeleteElement(accessoryTypes.indexOf(x));
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                    className="text-danger clickableIcon"
                                                    size="1x"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                </tbody>
            </Table>
            <div
                className="d-flex px-2 py-1 justify-content-end bg-white border-top"
                style={{ position: "sticky", right: "5px", bottom: "0", zIndex: 3 }}
            >
                <Button
                    onClick={() => {
                        reset();
                        setAdding(true);
                        setEditElement({
                            id: "",
                            name: "",
                            BoatTypes: [{ id: undefined as any }],
                        } as AccessoryType);
                        setValue("id", undefined as any);
                        setValue("name", "");
                        replaceBoatType([{ id: "", name: "", color: "", maxTime: 0, seats: 0, Sports: [] }]);
                    }}
                    variant="secondary"
                >
                    <FontAwesomeIcon icon={faPlus} className="text-white me-2" />
                    {t("accessoryTypeOverview.addAccessoryType")}
                </Button>
            </div>
            <StaffModal
                header={
                    isAdding
                        ? t("accessoryTypeOverview.addAccessoryType")
                        : t("accessoryTypeOverview.EditAccessoryType")
                }
                show={!!editElement}
                successText={
                    isAdding ? t("common.Add") : t("common.Edit")
                }
                disableNext={!isValid}
                loadingNext={loading}
                onHide={() => {
                    setEditElement(undefined);
                    setAdding(false);
                }}
                onSuccess={() => {
                    setLoading(true);
                    handleSubmit((data) => {
                        if (isAdding) {
                            addAccessoryType(data).then(async () => {
                                await getAccessoryTypes();
                                setEditElement(undefined);
                                setAdding(false);
                                setLoading(false);
                            }).catch(() => {
                                setLoading(false);
                            });
                        } else {
                            editAccessoryType(data).then(async () => {
                                await getAccessoryTypes();
                                setEditElement(undefined);
                                setAdding(false);
                                setLoading(false);
                            }).catch(() => {
                                setLoading(false);
                            });
                        }
                    })();
                    setLoading(false);
                }}
            >
                <Form>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={editElement?.name}
                        render={({ field }) => (
                            <div className="mb-2">
                                <Form.Label>{t("accessoryTypeOverview.Name")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...field}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name?.message}
                                </Form.Control.Feedback>
                            </div>
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: t("common.messages.required", {
                                    val: t("accessoryTypeOverview.Name"),
                                }),
                            },
                            validate: (value: string): ValidateResult => {
                                const isRedundant = accessoryTypes.some((accessoryType) => accessoryType.name.trim() === value.trim() && accessoryType.id !== editElement?.id);
                                //Redundancy Check
                                if (isRedundant) {
                                    return t("common.messages.nameExists") as ValidateResult;
                                }
                                //Empty String Check, You need 'val:..' for '{{val}}' in json.
                                if (value.trim() === "" || value.trim().length === 0) {
                                    return t("common.messages.required", {
                                        val: t("accessoryTypeOverview.Name"),
                                    }) as ValidateResult;
                                }
                            },
                        }}
                    />
                    <div className="d-flex justify-content-between">
                        <h5>{t("accessoryTypeOverview.BoatTypeName")}</h5>
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() => appendBoatType({ id: "", name: "", color: "", maxTime: 0, seats: 0, Sports: [] })}
                        >
                            +
                        </Button>
                    </div>

                    <ul>
                        {boattypeField.map((item: any, index: number) => (
                            <div key={item.id}>
                                <div className="d-flex my-2">
                                    <Controller
                                        name={`BoatTypes.${index}.id` as const}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Select {...field} isInvalid={!!errors.BoatTypes?.[index]?.id}>
                                                <option></option>
                                                {boattypes
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map((x) => (
                                                        <option key={x.id} value={x.id}>
                                                            {x.name}
                                                        </option>
                                                    ))}
                                            </Form.Select>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t("common.messages.required", {
                                                    val: t("accessoryTypeOverview.BoatType"),
                                                }),
                                            },
                                        }}
                                    />
                                    <Button
                                        className="mx-3"
                                        variant="danger"
                                        type="button"
                                        onClick={() => removeBoatType(index)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="text-white"
                                        />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </ul>
                </Form>
            </StaffModal>
            <StaffModal
                header={t("accessoryTypeOverview.DeleteAccessoryType")}
                hideColor="secondary"
                successText={t("common.Delete")}
                successColor="danger"
                show={deleteElement !== -1}
                loadingNext={loading}
                onHide={() => {
                    setDeleteElement(-1);
                }}
                onSuccess={async () => {
                    setLoading(true);
                    await deleteAccessoryType({ id: accessoryTypes[deleteElement].id });
                    await getAccessoryTypes();
                    setDeleteElement(-1);
                    setLoading(false);
                }}
            >
                <span>
                    {!accessoryTypes ? <div></div> : t("accessoryTypeOverview.messages.DeleteText", {
                        val: accessoryTypes[deleteElement]?.name,
                    })}
                </span>
            </StaffModal>
        </div>
    );
}

export default AccessoryTypeOverview;
