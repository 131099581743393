import { CheckIn } from "../types";

export async function markComment(done: boolean, id: string) {
  const apiString = done ? "noteDone" : "noteNotDone";
  const response = await fetch(`/api/checkin/${apiString}/${id}`, {
    method: "POST",
  });
  return response.status === 200;
}

export async function getCurrentCheckIn() {
  const response = await fetch("/api/checkin", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function getCheckInByBoatId(boatId: string) {
  const response = await fetch(`/api/checkin/boat/${boatId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function checkIn(data: CheckIn) {

  let {
    sport,
    boatName,
    accessory,
    startTime,
    estimatedEndTime,
    destination,
    additionalClients,
    fullNameOfResponsibleClient,
    isCourse,
  } = data;

  let start = new Date();
  start.setHours(
    Number(startTime.split(":")[0]),
    Number(startTime.split(":")[1]),
    0,
    0
  );
  let end = new Date();
  end.setHours(
    Number(estimatedEndTime.split(":")[0]),
    Number(estimatedEndTime.split(":")[1]),
    0,
    0
  );

  const response = await fetch("/api/checkin", {
    method: "POST",
    body: JSON.stringify({
      sport,
      boatName,
      accessory,
      startTime: start,
      estimatedEndTime: end,
      destination,
      additionalClients: additionalClients.map((x) => x.passenger),
      fullNameOfResponsibleClient,
      isCourse,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  let json = await response.json();
  return json;
}

export async function checkOut({
  id,
  note,
  bookingType,
}: {
  id: string;
  note: string;
  bookingType: string;
}) {
  const response = await fetch(`/api/checkout/${id}`, {
    method: "POST",
    body: JSON.stringify({
      bookingType: bookingType,
      note,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.ok;
}

export async function getAllCheckIns(semester: Number) {
  const response = await fetch("/api/checkins?year=" + semester, {
    method: "GET",
  });
  const json = await response.json();
  return json.result;
}

export async function getAllCurrentCheckIns() {
  const respons = await fetch("/api/checkins/current", {
    method: "GET",
  });
  const json = await respons.json();
  return json.result;
}

export async function getAgb() {
  const response = await fetch(`/static-instance/agb.json`, {
    method: "GET",
  });
  const json = await response.json();
  return json;
}

export async function editMemo(id: string, data: CheckIn) {
  const response = await fetch(`/api/checkin/editmemo/${id}`, {
    method: "POST",
    body: JSON.stringify({
      adminMemo: data.adminMemo,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.ok;
}