import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import Modal from "../../components/Modal";
import { checkOut } from "../../api/checkIn";

type FormData = {
  annotations: string;
};

async function checkoutHandler(d: any): Promise<boolean> {
  let success = await checkOut(d);
  return success;
}

function BookingSignOut() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { control, formState: { errors }, handleSubmit } = useForm<FormData>({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const onSubmit = (data: FormData) => {
    try {
      checkoutHandler({
        id: id,
        note: data.annotations,
        bookingType: "online",
      }).then((success: boolean) => {
        if (success) {
          // A Relative Navigation, which navigates from `(terminal)?/checkout/:id` to `(terminal)?/checkout/success`
          navigate("../../checkout/success");
        }
      });
    } catch (e) {
      alert("error");
    }
  };

  return (
    <Container className="pt-5">
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Modal>
            <h1 className="text-center">{t("bookingSignOut.title")}</h1>
            <p className="text-center">{t("bookingSignOut.subtitle")}</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="annotations"
                control={control}
                render={({ field }) => (
                  <div className="mb-4">
                    <Form.Control
                      as="textarea"
                      type="text"
                      {...field}
                      rows={5}
                      isInvalid={!!errors.annotations?.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.annotations?.message}
                    </Form.Control.Feedback>
                  </div>
                )}
                rules={{
                  maxLength: {
                    value: 10000,
                    message: t("common.messages.length", { val: 10000 })
                  },
                }}
              />
              <Button
                type="submit"
                variant="secondary"
                className="mt-2 w-100 mb-3"
              >
                {t("bookingSignOut.buttonSignOut")}
              </Button>
            </Form>
            {/* <p className="text-center">{t("bookingSignOut.labelButtonInfo")}</p> */}
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default BookingSignOut;
