import { useEffect, useState, useRef, useCallback } from "react";
import { Form, Table, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckIn } from "../../types";
import { getAllCheckIns, markComment, editMemo } from "../../api/checkIn";
import { faArrowDown, faArrowUp, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import TableSearch from "../../components/TableSearch";
import TableFilter from "../../components/TableFilter";
import TableDateFilter from "../../components/TableDateFilter";
import { Controller, useForm } from "react-hook-form";
import StaffModal from "../../components/StaffModal";

function Comments() {
  let mounted = useRef(true);
  const { t, i18n } = useTranslation();
  const [checkIns, setcheckIns] = useState<CheckIn[]>([]);
  const [commentSearch, setCommentSearch] = useState<string>("");
  const [boatNameSearch, setBoatNameSearch] = useState<string>("");
  const [accessoryNameSearch, setAccessoryNameSearch] = useState<string>("");
  const [adminMemoSearch, setAdminMemoSearch] = useState<string>("");

  const [authorSearch, setAuthorSearch] = useState<string>("");

  const [dateStartSearch, setDateStartSearch] = useState<string>("");
  const [dateEndSearch, setDateEndSearch] = useState<string>("");
  //Hack for refreshing when marking done/undone
  const [show, setShow] = useState<string[]>(["notdone"]);
  const [toggleElement, setToggleElement] = useState<CheckIn | null>(null);

  const [semester, setSemester] = useState<any>(new Date().getFullYear());

  const [sortBy, setSortBy] = useState<string>("date");
  const [isAsc, setIsAsc] = useState<boolean>(true);

  const [editElement, setEditElement] = useState<CheckIn | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  async function getcheckIns(year = -1) {
    if (year === -1) {
      year = semester;
    }
    const checkIns = await getAllCheckIns(year);
    if (mounted) {
      setcheckIns(checkIns);
    }
  }

  const toggle = useCallback(async () => {
    if (toggleElement === null) return;
    await markComment(!toggleElement.noteDone, toggleElement.id);
    setToggleElement(null);
  }, [toggleElement]);

  function setSort(by: string) {
    if (sortBy !== by) {
      setSortBy(by);
      setIsAsc(false);
    } else {
      setIsAsc(!isAsc);
    }
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<CheckIn>({
    mode: "onChange",
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    getcheckIns();
    toggle();
    return () => {
      mounted.current = false;
    }
  }, [toggle]);

  let sortedCheckIns = checkIns
    .map<CheckIn>((x: CheckIn) => {
      // Check to see whether a boat was already deleted. If so, replace the Boat with a dummy object, to still allow for the CheckIn to be rendered.
      if (x.Boat === null) {
        x.Boat = { id: "", name: "", status: 1, boattype: "None" };
      }
      return x
    })
    .filter((checkIn) => checkIn.returned && checkIn.note)
    .filter((checkIn) => {
      return (show.includes("done") && checkIn.noteDone) || (show.includes("notdone") && !checkIn.noteDone)
    })
    .filter((checkIn) => checkIn.note.toLocaleLowerCase().includes(commentSearch.toLocaleLowerCase()))
    .filter((checkIn) => {
      const adminMemo = checkIn.adminMemo || "";
      return adminMemo.toLowerCase().includes(adminMemoSearch.toLocaleLowerCase());
    })
    .filter((checkIn) => {
      if (dateEndSearch !== "" && dateStartSearch !== "") {
        return Date.parse(checkIn.date) >= Date.parse(dateStartSearch) && Date.parse(checkIn.date) <= Date.parse(dateEndSearch);
      }
      else if (dateStartSearch !== "" && dateEndSearch === "") {
        return Date.parse(checkIn.date) === Date.parse(dateStartSearch);
      }
      //dateStartSearch should never be Empty when dateEndSearch is not empty
      else {
        return true;
      }
    })
    .filter((checkIn) => checkIn.Boat.name.toLocaleLowerCase().includes(boatNameSearch.toLocaleLowerCase()))
    .filter((checkIn) => (checkIn.Accessory?.name || "").toLocaleLowerCase().includes(accessoryNameSearch.toLocaleLowerCase()))
    .filter((checkIn) => checkIn.fullNameOfResponsibleClient.toLocaleLowerCase().includes(authorSearch.toLocaleLowerCase()))
    .sort((a, b) => {
      if (sortBy === "date") {
        return (Date.parse(b.startTime) - Date.parse(a.startTime)) * (-1);
      }
      else if (sortBy === "boat") {
        return b.Boat.name.localeCompare(a.Boat.name) * (isAsc ? 1 : -1);
      }
      else if (sortBy === "accessory") {
        return (b.Accessory?.name || "").localeCompare(a.Accessory?.name || "") * (isAsc ? 1 : -1);
      }
      else {
        return ((b[sortBy as keyof CheckIn]?.toString() || "").localeCompare(a[sortBy as keyof CheckIn]?.toString() || "") || 0) * (isAsc ? 1 : -1)
      }
    });

  if (sortBy === "date" && isAsc) {
    sortedCheckIns = sortedCheckIns.reverse();
  };

  function renderTabMenu() {

    let curYear = new Date().getFullYear();
    let minYear = 2020;

    let years = [];
    for (let year = curYear; year >= minYear; year--) {
      years.push(year);
    }

    return (
      <div>
        <Tabs
          id="controlled-tab"
          activeKey={semester}
          onSelect={(k) => { setSemester(k); getcheckIns(Number(k)); }}
        >
          {years.map(v => {
            return (
              <Tab key={v} eventKey={v} title={v}>
              </Tab>
            )
          })
          }
        </Tabs>
      </div >
    )
  }

  return (
    <div className="m-1 h-100">
      {renderTabMenu()}
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>
              <div className="header">
                <span onClick={() => { setSort("boat") }}>
                  {t("comments.Boatname")}
                  {sortBy === "boat" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={boatNameSearch} onChange={(data) => { setBoatNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("accessory") }}>
                  {t("comments.Accessoryname")}
                  {sortBy === "accessory" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={accessoryNameSearch} onChange={(data) => { setAccessoryNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("fullNameOfResponsibleClient") }}>
                  {t("comments.Author")}
                  {sortBy === "fullNameOfResponsibleClient" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={authorSearch} onChange={(data) => { setAuthorSearch(data) }} />
              </div>
            </th>
            <th>
              {t("allRents.isCourse")}
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("note") }}>
                  {t("comments.Comment")}
                  {sortBy === "note" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={commentSearch} onChange={(data) => { setCommentSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("adminMemo") }}>
                  {t("comments.adminMemo")}
                  {sortBy === "adminMemo" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={adminMemoSearch} onChange={(data) => { setAdminMemoSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">

                <span onClick={() => { setSort("date") }}>
                  {t("comments.Date")}
                  {sortBy === "date" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableDateFilter stateFrom={dateStartSearch} stateTo={dateEndSearch} onChangeTo={(data) => {
                  setDateEndSearch(data);
                }} onChangeFrom={(data) => {
                  setDateStartSearch(data);
                }}></TableDateFilter>
              </div>
            </th>
            <th>
              <TableFilter options={[
                { label: t("comments.done"), value: "done" },
                { label: t("comments.notDone"), value: "notdone" },
              ]} state={show} onChange={(data) => { setShow(data) }}></TableFilter>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            sortedCheckIns
              .map((x, i) => (
                <tr key={x.id} style={{ cursor: "pointer" }}
                  onClick={() => {
                    reset();
                    setEditElement(x);
                  }}>
                  <td>{x.Boat.name === "" ? i18n.language === "de" ? (<em>Gelöschtes Boot</em>) : (<em>Deleted Boat</em>) : x.Boat.name}</td>
                  <td>{x.Accessory?.name || ""}</td>
                  <td>{x.fullNameOfResponsibleClient}</td>
                  <td>{x.isCourse ? t("allRents.Yes") : t("allRents.No")}</td>
                  <td className="table-text-field">{x.note}</td>
                  <td className="table-text-field">{x.adminMemo}</td>
                  <td>
                    {new Date(x.date).toLocaleDateString()}
                  </td>
                  <td onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                    <div className="d-flex">
                      <div className="mx-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className={`${x.noteDone ? "icon-green" : "icon-red"} clickableIcon`}
                          size="1x"
                          title={x.noteDone ? t("comments.MarkUndone") : t("comments.MarkDone")}
                          onClick={(e) => {
                            setToggleElement(x);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>

      <StaffModal
        header={t("boatManager.EditBoat")}
        show={!!editElement}
        successText={t("common.Edit")}
        loadingNext={loading}
        disableNext={!isValid}
        onHide={() => {
          setEditElement(undefined);
        }}
        onSuccess={() => {
          setLoading(true);
          handleSubmit((data) => {
            editMemo(editElement?.id || "", data).then(async () => {
              await getcheckIns();
              setEditElement(undefined);
              setLoading(false);
            });
          })()
        }}
      >
        <Form>
          <Controller
            name="Boat.name"
            control={control}
            defaultValue={editElement?.Boat.name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("boatManager.BoatName")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />

          <Controller
            name="Accessory.name"
            control={control}
            defaultValue={editElement?.Accessory?.name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accessoryOverview.AccessoryName")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />

          <Controller
            name="fullNameOfResponsibleClient"
            control={control}
            defaultValue={editElement?.fullNameOfResponsibleClient}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("comments.Author")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />

          <Controller
            name="isCourse"
            control={control}
            defaultValue={editElement?.isCourse}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Check
                  type="checkbox"
                  id="isCourse"
                  name={field.name}
                  disabled={true}
                  defaultChecked={editElement?.isCourse}
                  inline
                />
                <Form.Label>{t("allRents.isCourse")}</Form.Label>
              </div>
            )}
          />

          <Controller
            name="date"
            control={control}
            defaultValue={editElement?.date}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("comments.Date")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  disabled={true}
                />
              </div>
            )}
          />

          <Controller
            name="note"
            control={control}
            defaultValue={editElement?.note}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("comments.Comment")}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  {...field}
                  disabled={true}
                  rows={5}
                />
              </div>
            )}
          />

          <Controller
            name="adminMemo"
            control={control}
            defaultValue={editElement?.adminMemo || ""}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("comments.adminMemo")}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  {...field}
                  rows={5}
                />
              </div>
            )}
          />
        </Form>
      </StaffModal>

    </div>
  );
}

export default Comments;
