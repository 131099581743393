import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function StaffModal(props: {
  children?: any;
  header?: string;
  hideColor?: string;
  successColor?: string;
  onSuccess?: any;
  onHide?: any;
  successText?: string;
  hideSuccess?: boolean;
  hideText?: string;
  show: boolean;
  disableNext?: boolean;
  loadingNext?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      header={props.header}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{props.children}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          className="float-left"
          variant={props.hideColor || "secondary"}
          onClick={props.onHide}
        >
          {props.hideText || t("staffModal.Cancel")}
        </Button>
        {!props.hideSuccess ? <Button
          variant={props.successColor || "success"}
          onClick={props.onSuccess} disabled={props.disableNext || props.loadingNext}
        >
          {props.loadingNext && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          {props.successText || t("staffModal.Done")}
        </Button> : <></>}

      </Modal.Footer>
    </Modal>
  );
}
export default StaffModal;
