import { useState } from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { GetFontColorForBackground } from "../styles/colours";


function BoatTile(props: any) {
    const [hover, setMouseHover] = useState<boolean>(false);
    let typecolor = props.typecolor;

    let colorclass = "";
    if (props.isInUse) {
        colorclass = (props.overdue ? 'bg-danger' : 'bg-given-color');
    } else if (props.blocked) {
        colorclass = 'bg-info';
    } else if (props.typecolor === undefined) {
        colorclass = 'bg-success';
    } else {
        colorclass = 'bg-given-color';
    }

    let textcolor = "#FFFFFF";
    if (colorclass === 'bg-given-color') {
        textcolor = GetFontColorForBackground(typecolor);
    }

    return (
        <Col>
            <div className={`card-body shadow position-relative mb-1 rounded-2 ${colorclass} boatTile`}
                onClick={props.clickEvent}
                style={{ height: "110px", background: `${typecolor}`, color: textcolor }}
            >
                <div className="text-uppercase fs-4 tile-label-boatName position-absolute ms-2 mt-1 top-0 start-0 fw-bold"
                    style={{ maxWidth: "calc(100%-6em)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{props.boatName}</div>
                {props.time && <div className="tile-label-time position-absolute me-2 mt-2 top-0 end-0">{(new Date(props.time))?.getHours().toString().padStart(2, "0") + ':' + (new Date(props.time))?.getMinutes().toString().padStart(2, "0")}</div>}
                {props.accessory &&
                    <div className="tile-label-additionalClients position-absolute ms-2 start-0" style={{ marginTop: "1.2em" }}>{props.accessory}</div>}
                <div className="tile-label-additionalClients position-absolute ms-2 start-0" style={{ marginTop: "2.6em" }}>{props.isCourse}</div>
                <span>
                    <div className="tile-label-additionalClients position-absolute ms-2 mb-2 bottom-0 start-0">{props.additionalClients}</div>
                    {props.responsibleClient !== undefined &&
                        (<div className="tile-label-additionalClients position-absolute me-2 mb-2 bottom-0 end-0 " style={{ fontWeight: "bold", width: "50%", overflow: "hidden", textAlign: "right", textOverflow: "ellipsis" }}>{props.responsibleClient}</div>)
                    }
                </span>
                {!props.isTrainer && props.blocked &&
                    <div className="tile-label-overdue position-absolute me-2 mb-2 bottom-0 end-0 fw-bold">
                        <div className="mx-2" onMouseOver={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} onClick={(event) => { props.changeLock(); event.stopPropagation() }}>
                            <FontAwesomeIcon icon={hover ? faLockOpen : faLock} className={`${hover ? 'text-primary' : 'text-danger'} changeIcon`} size="2x" />
                        </div>
                    </div>
                }
                {!props.isTrainer && !props.isInUse && !props.blocked &&
                    <div className="tile-label-overdue position-absolute me-2 mb-2 bottom-0 end-0 fw-bold">
                        <div className="mx-2" onMouseOver={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} onClick={(event) => { props.changeLock(); event.stopPropagation() }}>
                            <FontAwesomeIcon icon={hover ? faLock : faLockOpen} className={`${hover ? 'text-danger' : 'text-primary'} changeIcon`} size="2x" />
                        </div>
                    </div>
                }
            </div>
        </Col>

    );
}

export default BoatTile;
